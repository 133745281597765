import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import 'aos';
import axios from "axios";
import { useForm } from "react-hook-form";


let serviceList = [
    {
        title: 'SAP',
        // content: "Regarding system applications and products (SAP), KO-Innovations continue..",
        content: "With comprehensive experience in diverse SAP modules, our company delivers bespoke solutions to streamline and elevate your business processes",
        icon: 'sap.png',
        readmore: '/service/sap'
    },
    {
        title: 'Cognitive Automation',
        content: "We specialize in cutting-edge solutions across AI, Machine Learning, and Robotic Process Automation (RPA) to drive intelligent automation",
        icon: 'rpa.png',
        readmore: '/service/ai'
    },
    // {
    //     title: 'AI',
    //     content: "Artificial intelligence is the simulation of human intelligence processes by machines, especially computer systems. ",
    //     icon: 'digital.png',
    //     readmore: '/service/ai'
    // },
    // {
    //     title: 'Machine Learning',
    //     content: "Machine learning (ML) is a field of inquiry devoted to understanding and building methods that 'learn', that is, methods that leverage data to improve performance on some set of tasks.",
    //     icon: 'digital.png',
    //     readmore: '/service/ai'
    // },
    {
        title: 'Software Innovation Suite',
        // content: "Step inside the center of excellence where we build bold, engaging new websites. ",
        content: "We craft and implement sophisticated digital and ERP products to enhance and revolutionize your business operations",
        icon: 'digital.png',
        readmore: '/service/digital-products'
    },
    {
        title: '3DX',
        content: "Transforming digital interactions with advanced 3DX, augmented reality (AR) and virtual reality (VR) solutions",
        icon: '3dx.png',
        readmore: '/service/3dexperience'
    },
];
let ideasList = [
    {
        title: 'Ideation Stage',
        content: "This is the beginning stage of a creative process where one formulates the seed of an Innovation – the Idea. This involves coining nimble and disruptive ideas through extensive research and inclusive brainstorm sessions.",
        icon: 'idea1.png'
    },
    {
        title: 'Innovation Stage',
        content: "Once the idea is formulated it reaches the innovation stage where it is implemented. The idea transforms into a sustainable Innovation on digital platforms through investments in emerging technologies to prototype designs",
        icon: 'idea2.png'
    },
    {
        title: 'Impact Stage',
        content: "The final and crucial part is the Impact stage. Here we help deliver the IT to the business organisations by building, scaling and delivering digital technology solutions. We assess the Impact in a systematic way and deduce information for further growth.",
        icon: 'idea3.png'
    }
];

let clients = ['1.jpeg', "2.jpeg", "3.jpeg", "4.jpeg", "5.png", "6.png", "7.jpg", "8.jpg", "9.jpeg", "tata.jpg","safa.jpg", "weekendz.jpg","shamel.jpg", "partner3.png"];

function Home() {
    const [btnLoading, setBtnLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const { register, setError, formState: { errors, isValid, isSubmitting }, reset, handleSubmit } = useForm();
    console.log('isSubmitting', isSubmitting);


    const onSubmit = async data => {
        console.log(isValid, isSubmitting);
        setBtnLoading(true)
        try {
            axios.post('http://localhost:3000/contactus', data).then((response) => {
                console.log(response)
                if (response.status == 200) {
                    setTimeout(() => {
                        setBtnLoading(false);
                        setIsSubmit(true);
                        reset();
                    }, 1000);
                    setTimeout(() => {
                        setIsSubmit(false);
                    }, 3000);
                }
            });
        } catch (error) {
            console.log(error)
            setBtnLoading(false)
        }
    };

    const options = {
        items: 1,
        nav: true,
        rewind: true,
        autoplay: true
    };

    return (
        <>
            <section className='home_banner p-0'>
                <OwlCarousel className='owl-theme' autoplay={true} items={1} loop margin={10} nav dots={false} animateIn='fadeIn' animateOut='fadeOut'>
                    <div class='item'>
                        <Box className='banner_wrapper'>
                            <img src={require('../assets/images/banner/1.jpg')} />
                            <Box className='caption right'>
                                <h1 className='title'>Grow Your business <br />using our services</h1>
                                {/* <button className='action_btn'>Get Started</button> */}
                            </Box>
                        </Box>
                    </div>
                    <div class='item'>
                        <Box className='banner_wrapper'>
                            <img src={require('../assets/images/banner/2.jpg')} />
                            <Box className='caption'>
                                <h1 className='title'>Ideas that Innovate <br /> and Impact Lives</h1>
                                {/* <button className='action_btn'>Get Started</button> */}
                            </Box>
                        </Box>
                    </div>
                    <div class='item'>
                        <Box className='banner_wrapper'>
                            <img src={require('../assets/images/banner/3.jpg')} />
                            <Box className='caption'>
                                <h1 className='title'>We Promote & Grow Your Business</h1>
                                {/* <button className='action_btn'>Get Started</button> */}
                            </Box>
                        </Box>
                    </div>
                    <div class='item'>
                        <Box className='banner_wrapper'>
                            <img src={require('../assets/images/banner/4.jpg')} className="bottom" />
                            <Box className='caption'>
                                <h1 className='title'>Artificial Intelligence</h1>
                                {/* <button className='action_btn'>Get Started</button> */}
                            </Box>
                        </Box>
                    </div>
                    <div class='item'>
                        <Box className='banner_wrapper'>
                            <img src={require('../assets/images/banner/5.jpg')} className="bottom" />
                            <Box className='caption right'>
                                <h1 className='title' style={{ marginBottom: 10 }}>SAP</h1>
                                <h3 className='subtitle'>Real-time Production reporting and material issue and return.</h3>
                                {/* <button className='action_btn'>Get Started</button> */}
                            </Box>
                        </Box>
                    </div>
                </OwlCarousel>
                <div class="shape_divider01">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" class="shape-fill"></path>
                    </svg>
                </div>
            </section>

            <section className='service'>
                <Box className='container'>
                    <h1 className='center section_title'>Our Services</h1>
                    <Grid container spacing={2} justifyContent="center">
                        {serviceList && serviceList.map((data) => (
                            <Grid item md={3} sm={12}>
                                <a href={data.readmore} to={data.readmore} className='service_anchor'>
                                <Box className='service_card'>
                                    <Box className='icon'>
                                        <img src={require(`../assets/images/icons/${data.icon}`)} />
                                    </Box>
                                    <Box className='title'>{data.title}</Box>
                                    <Box className='content'>{data.content}</Box>
                                    {/* <a className="readmore" href={data.readmore} to={data.readmore} >Read More</a> */}
                                </Box>
                                </a>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </section>

            {/* <section className='our_products' style={{ paddingTop : 0 }}>
                <Box className='container'>
                    <h1 className='center section_title'>Our Products</h1>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item md={4}>
                            <Box className='product_box'>
                                <img src={require('../assets/images/products/van_logo.png')} width="100%"/>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box className='product_box'>
                                <img src={require('../assets/images/products/rdd_logo.png')}  width="100%"/>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box className='product_box'>
                                <Typography>Hamrah</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section> */}


            <section className='welcome center'>
                <div class="welcome_shape_top">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <Box className='container'>
                    <Box className='center section_subtitle'>WELCOME TO KO INNOVATION</Box>
                    <h1 className='center section_title light'>Transforming Ideas into Interactive <br />and Engaging Digital Realities</h1>

                    <Box >
                        <Typography>The world around us change incredibly every passing day. There has never been more pressing need for organizations to rethink and realign their businesses for a changed world.</Typography>
                        <Typography>Growth and profitability of a business is highly driven by various technologies across all aspects of business. In today’s world, keeping up with the global technological advancements is not just an added advantage but an indispensable need.</Typography>
                        <Typography >Adopting innovations makes your business smart and agile; businesses need to be constantly evolving ideas to stay ahead of the curve. It is imperative to build Ideas and Innovations that leave a lasting Impact on lives.</Typography>
                        {/* <button className='btn btn-outline'>Explore</button> */}
                    </Box>
                </Box>
                <div class="welcome_shape_bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </section>


            <section className='ideas'>
                <Box className='container'>
                    <h1 className='center section_title'>From Idea To Impact</h1>
                    <Grid container spacing={2}>
                        {ideasList && ideasList.map((data) => (
                            <Grid item md={4} sm={12}>
                                <Box className='service_card card_style1'>
                                    <Box className='icon dark'>
                                        <img src={require(`../assets/images/icons/${data.icon}`)} style={{ filter: 'invert(1)' }} />
                                    </Box>
                                    <Box className='title'>{data.title}</Box>
                                    <Box className='content'>{data.content}</Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </section>


            <section className='our_roots dark center ' id='dotanimate_section'>
                <Box className='container'>
                    <Grid container justifyContent={'center'}>
                        <Grid item md={8}>
                            <h1 className='section_title light'>Our Roots</h1>
                            <Box>
                                <Typography>The word ‘KO’ (கோ) of KO Innovation originates from Tamil language, which is one of the ancient cultural languages in the world. KO is predominantly used to denote a leader, saviour or a king.</Typography>
                                <Typography>The word ‘KO’ in KO-innovations signifies Leader as we aspire to be a social impact business leader and positively influence the lives around us with our novel ideas and innovations.</Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='dotanimate_wrap'>
                        <div class="bg-animation">
                            <div id='stars'></div>
                            <div id='stars2'></div>
                            <div id='stars3'></div>
                            <div id='stars4'></div>
                        </div>
                    </Box>
                </Box>
            </section>


            {/* <section className='enquiry_section light' id='enquiry_contact'>
                <Box className='container'>
                    <Grid container spacing={3} alignItems={'center'}>
                        <Grid item md={7}>
                            <h1 className='section_title'>Care To Join Us?</h1>
                            <Typography>We’re a talented global team creating digital experiences that merge imagination and technology. Sound good? We’re hiring.</Typography>
                           

                            <form onSubmit={handleSubmit(onSubmit)} className='contact-form'>
                                <Box className='form-group'>
                                    <input
                                        placeholder='Name'
                                        {...register("name", { required: "Please enter your Name." })} // custom message
                                    />
                                    {errors.name && <p className='error'>{errors.name.message}</p>}

                                </Box>
                                <Box className='form-group' >
                                    <input
                                        placeholder='Email'
                                        {...register("email", { required: "Please enter your Email." })} // custom message
                                    />
                                    {errors.email && <p className='error'>{errors.email.message}</p>}
                                </Box>
                                <Box className='form-group'>
                                    <input
                                        placeholder='Mobile'
                                        {...register("mobile", { required: "Please enter your Mobile." })} // custom message
                                    />
                                    {errors.mobile && <p className='error'>{errors.mobile.message}</p>}
                                </Box>
                                <Box className='form-group'>
                                    <textarea
                                        placeholder='Message'
                                        {...register("message", { required: "Please enter your Message." })} // custom message
                                    />
                                    {errors.message && <p className='error'>{errors.message.message}</p>}
                                </Box>

                                {isSubmit && <div style={{ color: "#8bc34a", marginTop: 20 }}>Thank you for filling out your information!</div>}
                                <button type="submit" className='submit_btn'  >Send Message
                                    {btnLoading && <span className='btn_loader'> </span>}
                                </button>
                            </form>
                        </Grid>
                        <Grid item md={5}>
                            <Box className='enquiry_img'>
                                <img src={require('../assets/images/enquiry.png')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section> */}


            <section className='client_partners dark'>
                <Box className='container'>
                    <h1 className='center section_title light'>Our Clients</h1>
                    <OwlCarousel className='owl-theme' items={4} loop margin={20} nav dots={false} autoplay={true} slideTransition={'linear'} smartSpeed={1000} autoplaySpeed={2000} >
                        {clients.map(data =>
                            <div class='item'>
                                <Box className='banner_wrapper'>
                                    <img src={require(`../assets/images/clients/${data}`)} />
                                </Box>
                            </div>
                        )}
                    </OwlCarousel>


                    <h1 className='center section_title light mt-30'>Our Partners</h1>
                    {/* <h1 className='center section_subtitle light'>“We KO innovate with Octalpha”</h1> */}
                    <Box className='ourpartners'>
                        <Box className='item'>
                            <img src={require(`../assets/images/clients/partner.png`)} width={'100%'} />
                        </Box>
                        <Box className='item'>
                            <img src={require(`../assets/images/clients/partner2.png`)} width={'100%'} />
                        </Box>
                        {/* <Box className='item'>
                            <img src={require(`../assets/images/clients/partner3.png`)} width={'100%'} />
                        </Box> */}
                    </Box>
                </Box>
            </section>
        </>

    )
}

export default Home
