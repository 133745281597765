import React from 'react'
import Header from '../components/header';
import Footer from '../components/Footer';
import { green, orange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  BrowserRouter,
  Outlet
} from "react-router-dom";

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: "#f08231",
      contrastText: '#fff'
    },
  },
});

function Layout() {
  const { width, height } = window
    return (
      <>
      {/* <Confetti
        width={width}
        height={height }
        numberOfPieces ={ 600 }
        tweenDuration ={ 40000 }
        friction={0.98}
        recycle={false}
      /> */}
        <ThemeProvider theme={theme}>
          <div className="container_wrap">
            <Header />
            <Outlet />
            <Footer />
          </div>
        </ThemeProvider>
      </>
    )
}

export default Layout
