import React from 'react';
import headerbg from '../../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';

function Digital() {
    return (
        <Box className="page custom_products">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>Software Innovation Suite</h1>
            </section>

            {/* <section className='process light'>
                <Box className='container'>
                    <Grid container justifyContent={'center'}>
                        <Grid item md={8}>
                            <Box className='center section_subtitle' sx={{ mb: 1 }}>5 Steps</Box>
                            <h1 className='section_title center'>UX Design Process</h1>
                            <img src={require('../../assets/images/process.png')} width="100%" />
                        </Grid>
                    </Grid>
                </Box>
            </section> */}


            <section className='product section light'>
                <Box className='container'>
                    {/* <h1 className='section_title center'>Our Products</h1> */}
                    <h3 className='sect_title center' style={{ color: "#021753", marginBottom:'50px' }}>At the forefront of technology, we specialize in developing innovative digital solutions, ERP systems, and low-code/no-code platforms, tailored to a wide range of industries and tech stacks, ensuring customized and efficient solutions for your unique business requirements.</h3>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <h3 className='title_next'>Advanced Enterprise Fleet Management</h3>
                            <Typography>KoFleetz, the premier solution for modernizing fleet management and logistics operations. In today's competitive business landscape, efficient management of vehicles, drivers, vendors, contractors, trips, and shipments is essential for the success of large enterprises. KoFleetz empowers businesses of all sizes and industries to navigate these challenges with ease and effectiveness.</Typography>

                            {/* <h3>Digital strategy</h3> */}
                            <ul className='mock_list'>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Monitors driver behavior, including harsh driving, lane changes, and idle time</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Enhances route planning and systematically schedules trips to save time and reduce costs</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Provides live tracking and geofencing for comprehensive real-time visibility</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Offers onboard diagnostics and compliance features like inspections and checklists to minimize downtime</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Streamlines the management of documents, contracts, and vendors for enhanced organization and efficiency</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Offers customizable fleet management solutions tailored for diverse businesses, including distribution, delivery, and various mobility needs</li>
                            </ul>
                        </Grid>
                        <Grid item md={6} sx={{alignContent:"center"}}>
                            <Box className='mock_img right'>
                                <Box className='mockup_logo right' >
                                    <img src={require('../../assets/images/logo-dark.png')} />
                                </Box>
                                <img style={{backgroundColor:'#f3e5f5'}} src={require('../../assets/images/kofleetz-truck.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            {/* <Grid container spacing={4}>
                        <Grid item md={6}>
                            <h3 className='title_next'>Mobile Sales & Distribution</h3>
                            <Typography>Sales Diary has a unique solution to best manage inventory, delivery, cash transactions, damages, and returns of van goods. Advanced features for route management, location tracking, on-spot billing and real-time updates on the goods.</Typography>

                            <h3>Digital strategy</h3>
                            <ul className='mock_list'>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Testing &amp; optimization</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Back-End Development</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Modern companies are built</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Mobile Development</li>
                            </ul>
                        </Grid>
                        <Grid item md={6}>
                            <Box className='mock_img right'>
                                <Box className='mockup_logo right'>
                                    <img src={require('../../assets/images/products/van_logo.png')} />
                                </Box>
                                <img src={require('../../assets/images/products/mockup1.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid> */}

            <section className='product section '>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <Box className='mock_img left'>
                                <Box className='mockup_logo left'>
                                    <img src={require('../../assets/images/products/fitout_logo.png')} />
                                </Box>
                                <img style={{height:'450px'}} src={require('../../assets/images/products/fitoutmockup.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <h3 className='title_next'>RDD - Fitout Management Application</h3>
                            <Typography>RDD (Retail Design Delivery) offers a holistic solution for the comprehensive operation and maintenance of building infrastructure, services, and utilities. This system is designed to ensure that all aspects of a building’s infrastructure are used efficiently and in alignment with their intended purposes, optimizing performance and reliability</Typography>
                            <Typography>The Mall Management System, an integral part of this approach, encompasses a wide range of functions essential for the smooth operation of a mall. It includes the management of daily operations, facilities upkeep, security measures, financial accounting, and common area maintenance. Additionally, it covers marketing strategies, leasing activities, and any other operational aspects related to mall management, ensuring a cohesive and efficient management framework for both the physical and operational elements of the mall.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section light'>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <h3 className='title_next'>Smart Coupon - Coupon Management System</h3>
                            <Typography>Smart Coupon is a state-of-the-art application designed to simplify coupon management, specifically tailored to meet the needs of malls and their loyalty programs. This innovative platform enhances the shopping experience by encouraging customers to visit a variety of malls, deepening their engagement, and fostering long-term brand loyalty.</Typography>
                            <Typography>Currently making a significant impact in Kuwait’s top malls, including 360, Al Kout, and Al-Khiran, Smart Coupon helps malls boost customer satisfaction, drive repeat visits, and increase overall sales. Embrace the Smart Coupon revolution and transform your shopping experience with us.</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Box className='mock_img right'>
                                <Box className='mockup_logo left maintenance' >
                                    <img style={{width:'150px', height:'40px'}} src={require('../../assets/images/coupon_head.png')} />
                                </Box>
                                <img src={require('../../assets/images/smartcoupon.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>

        </Box>
    )
}

export default Digital
