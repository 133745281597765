import React from 'react';
import headerbg from '../../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';

function SAP() {
    return (
        <Box className="page sap">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>SAP</h1>
            </section>

            <section className='aboutsect section1' >
                <Box className='container' style={{display:'flex'}}>
                    <Box className='sap_logo'>
                        <img src={require('../../assets/images/sap.jpg')} />
                    </Box>
                    <h3 className='sect_title center' style={{ color: "#021753" }}>As a SAP certified partner, our company leverages deep expertise across a broad spectrum of SAP modules. We provide tailored solutions designed to optimize your business processes, enhance operational efficiency, and drive strategic success.</h3>
                </Box>
            </section>


            {/* {tabList && tabList.map((data, i) => (
                <section className='aboutsect section4 p-0' key={i} data-key={i} >
                    <Grid container spacing={2}>
                        <Grid md={6}>
                            <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                                <h1 className='section_title light' >{data.title} </h1>
                                {data?.list.length < 0 && data?.list.map((textlist, index) => (
                                    <Typography key={index}>{textlist} </Typography>
                                ))}
                            </Box>
                        </Grid>
                        <Grid md={6}>
                            <Box className='boxbg_img fullwidth'>
                                <img src={require(`../../assets/images/implementation.jpeg`)} width={'100%'} />
                            </Box>
                        </Grid>
                    </Grid>
                </section>
            ))} */}

            <section className='aboutsect section4 p-0'  >
                <Grid container >
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >SAP Implementation</h1>
                            <Typography >We help our customers build great solutions through smooth SAP Implementation with customer-centric approach. We understand each SAP project is unique in objectives, scope and priorities. We focus on following key areas.</Typography>

                            <ul className='list'>
                                <li>Defining project goals and objectives</li>
                                <li>Clarifying the scope of implementation</li>
                                <li>Defining an implementation strategy</li>
                                <li>Defining overall project schedule and implementation sequence</li>
                                <li>Establishing the project organization and committees</li>
                                <li>Assigning and aligning right resources.</li>
                            </ul>
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require(`../../assets/images/about/sap_implementation.jpg`)} width={'100%'} />
                        </Box>
                    </Grid>
                </Grid>
            </section>

            <section className='aboutsect section4 p-0'  >
                <Grid container >
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require('../../assets/images/about/cloud_migration.jpg')} width={'100%'} />
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >SAP Cloud Migration</h1>
                            <Typography >Migrating SAP infrastructure to the cloud makes the perfect choice. It will help you cut down the costs associated with managing your infrastructure, providing support services, licensing and managed services.</Typography>
                            <Typography>We seamlessly migrate on-premises SAP system to cloud platform. We provide you to trial your SAP migration before you actually do it. With our proof of concept package model, you can trial moving one of your own non-productive SAP systems to cloud and compare your existing on-premise solution. This means you have an incredibly low-risk environment in which to trial your SAP system in the cloud, before making any mission-critical decisions.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </section>

            <section className='aboutsect section4 p-0'  >
                <Grid container >
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >SAP Upgrade</h1>
                            <Typography>Once technologies and businesses evolve, Business needs to explore the new innovation of SAP releases and choose the relevant business packages that can upgrade their business to the next level.</Typography>
                            <Typography>This is the major reason why companies should undertake upgrade on time and avoid disruptionof your SAP services. So, we play a vital role to suggest and support the changes to your ERP package.</Typography>
                            <Typography>Our expertise team force, assists our customers for upgrade project that is implemented across companies to upgrade their SAP system and enables latest released SAP versions.</Typography>
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth' style={{ backgroundColor :'#ffffff' }}>
                            <img src={require('../../assets/images/about/s4_sap_upgrade.jpg')} width={'100%'} style={{ objectFit : 'contain' }} />
                        </Box>
                    </Grid>
                </Grid>
            </section>

            <section className='aboutsect section4 p-0'  >
                <Grid container >
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require('../../assets/images/about/sap_integration.png')} width={'100%'} />
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10, height : "64%" }}>
                            <h1 className='section_title light' >SAP Integration Services</h1>
                            <Typography>SAP Integration Service that allows you to smoothly integrate on-premise and cloud-based applications and processes with tools and prebuilt content managed by SAP.</Typography>
                            <ul className='list'>
                                <li>Defining project goals and objectives</li>
                                <li>We provide integration services through SAP CPI (Cloud Platform Integration) and securely synchronize with third party or other SAP platforms with API, Web services.</li>
                            </ul>
                        </Box>
                    </Grid>
                </Grid>
            </section>

            <section className='aboutsect section4 p-0'  >
                <Grid container >
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >SAP Support Services – On Going Maintenance / Continuous Improvements</h1>
                            <Typography>AP Preferred care services are provided for personalized engagement with our customers for all SAP on-premise products.</Typography>
                            <Typography>We provide 24/7 operation on demand. we offer a wide range of high-level support services tailored to empower your business from implementation to improving your SAP processes.</Typography>
                            <Typography>Our proactive support service enables continuous improvements and business continuity. KO Innovation support services, relieves you from day-to-day maintenance chores and helps you focus on business excellence.</Typography>
                        </Box>
                    </Grid>
                        <Grid md={6} item>
                            <Box className='boxbg_img fullwidth'>
                            <img src={require('../../assets/images/about/support_service.jpg')} width={'100%'} />
                            </Box>
                        </Grid>
                </Grid>
            </section>
        </Box>
    )
}

export default SAP;

const tabList = [
    {
        title: "SAP Implementation",
        textList: [
            {
                text: "We help our customers build great solutions through smooth SAP Implementation with customer-centric approach. We understand each SAP project is unique in objectives, scope and priorities. We focus on following key areas."
            }
        ],
        list: [
            "Defining project goals and objectives",
            "Clarifying the scope of implementation",
            "Defining an implementation strategy",
            "Defining overall project schedule and implementation sequence",
            "Establishing the project organization and committees",
            "Assigning and aligning right resources."
        ]
    },
    {
        title: "SAP Cloud Migration",
        textList: [
            {
                text: "Migrating SAP infrastructure to the cloud makes perfect choice. It will help you cut down the costs associated with managing your infrastructure, providing support services, licensing and managed services."
            },
            {
                text: "we seamlessly migrate on-premises SAP system to cloud platform. We provide you to trial your SAP migration before you actually do it. With our proof of concept package model, you can trial moving one of your own non-productive SAP systems to cloud and compare your existing on-premise solution. This means you have an incredibly low-risk environment in which to trial your SAP system in the cloud, before making any mission-critical decisions."
            }
        ],
    },
    {
        title: "SAP Upgrade",
        textList: [
            {
                text: "Once technologies and businesses evolve, Business needs to explore the new innovation of SAP releases and choose the relevant business packages that can upgrade their business to next level."
            },
            {
                text: "This is the major reason why companies should undertake upgrade on time and avoid disruptionof your SAP services. So, we play a vital role to suggest and support the changes to your ERP package."
            },
            {
                text: "Our expertise team force, assists our customers for upgrade project that is implemented across companies to upgrade their SAP system and enables latest released SAP versions."
            }
        ],
    },
    {
        title: "SAP Integration Services",
        list: [
            "Defining project goals and objectives",
            "We provide integration services through SAP CPI (Cloud Platform Integration) and securely synchronize with third party or other SAP platforms with API, Web services.",
        ]
    },
    {
        title: "SAP Support Services – On Going Maintenance / Continuous Improvements",
        textList: [
            {
                text: "AP Preferred care services are provided for personalized engagement with our customers for all SAP on-premise products."
            },
            {
                text: "We provide 24/7 operation on demand. we offer a wide range of high-level support services tailored to empower your business from implementation to improving your SAP processes."
            },
            {
                text: "Our proactive support service enables continuous improvements and business continuity. KO Innovation support services, relieves you from day-to-day maintenance chores and helps you focus on business excellence."
            }
        ],
    }

]
