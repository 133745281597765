import React from 'react';
import headerbg from '../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';

function Career() {
    return (
        <Box className="page career">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>Careers</h1>
            </section>

            <section className='enquiry_section light' >
                <Box className='container'>
                    <Grid container spacing={3} justifyContent={'center'}  alignItems="center">
                        
                        <Grid item md={5}>
                            <Box>
                                <img src={require('../assets/images/career.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={7}>
                            <h1 className='section_title '>Join us </h1>
                            <Typography>We’re a talented global team creating digital experiences that merge imagination and technology. Sound good? We’re hiring.</Typography>
                            <form className='contact-form'>
                                <Grid container spacing={3}>
                                    <Grid item md={6}>
                                        <Box className='form-group'>
                                            <label>Name</label>
                                            <input type={'text'} placeholder="Name" />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box className='form-group'>
                                            <label>Email</label>
                                            <input type={'text'} placeholder="Email" />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box className='form-group'>
                                    <label>Preferred Role</label>
                                    <input type={'text'} placeholder="your Preferred Role" />
                                </Box>
                                <Box className='form-group'>
                                    <label >Message</label>
                                    <textarea placeholder="Message" />
                                </Box>
                                <Box className='form-group fileupload'>
                                    <label>Upload Your Resume</label>
                                    <input type={'file'} placeholder="Type your Preferred Role" />
                                </Box>
                                <Box className='form-group'>
                                    <button className='submit_btn'>Send Message</button>
                                </Box>

                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </section>
        </Box>
    )
}

export default Career
