import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import headerbg from '../../assets/images/about_header.jpg'

const ThreeDx = () => {
    return (
        <Box className="page custom_products">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>3Dxperience</h1>
            </section>

            <section className='aboutsect section1' style={{ paddingBottom : 20 }}>
                <Box className='container'>
                    <Grid container >
                        <Grid item md={5}>
                            <Box className='imagebox'>
                                <img style={{width:'400px', height:'400px'}} src={require('../../assets/images/3dx2.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={7}>
                            <Box className='rightbox'>
                                <h3>Step into the Future with Augmented Reality (AR) and Virtual Reality (VR) Technologies</h3>
                                <Typography>At KO Innovation, we are committed to transforming your organization with the latest advancements in Augmented Reality (AR) and Virtual Reality (VR). Our innovative 3DX solutions are designed to deliver unparalleled interactive experiences across various aspects of your business, including training, design, and customer engagement.</Typography>
                                <h3>Unlock the Potential of AR and VR</h3>
                                <Typography>Imagine a future where your team’s training sessions are no longer confined to traditional methods, where your design processes are revolutionized by immersive technology, and where customer engagement reaches new levels of interactivity. With KO Innovation’s 3DX solutions, this future is within your grasp.</Typography>
                                {/* <Typography>- AR and VR Training Solutions</Typography>
                                <Typography>- Virtual Tours and Simulations</Typography>
                                <Typography>- Product Visualization</Typography>
                                <Typography>- Metaverse and Gaming</Typography>
                                <Typography>- Interactive Product Design</Typography>
                                <Typography>- Customer Service Enhancement</Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section light'>
                <Box className='container'>
                    {/* <h1 className='section_title center'>Our Products</h1> */}
                    <h3 className='sect_title center' style={{ color: "#021753", marginBottom:'50px', marginTop:'-40px' }}>At Koinnovation Solutions, we are at the forefront of integrating cutting-edge technologies to create immersive and transformative experiences. Our focus lies in harnessing the power of 3DExperience, Augmented Reality (AR), and Virtual Reality (VR) to revolutionize how businesses and individuals interact with the digital world. Our innovative solutions span across various domains including training, design, customer engagement, and more, offering a comprehensive suite of interactive experiences that drive engagement and efficiency.</h3>
                    <Grid container spacing={4}>
                        <Grid item md={7} sx={{alignContent:"center"}}>
                            <h3 className='title_next'>AR and VR Training Solutions</h3>
                            <Typography>Immersive Learning: Transform your training programs with AR and VR technologies that offer hands-on, interactive learning experiences. Our solutions allow trainees to engage in realistic simulations, practice complex tasks, and receive real-time feedback, all within a virtual environment. This approach enhances learning outcomes and reduces training time and costs.</Typography>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={4}>
                            <Box className='mock_img1 right'>
                                <img style={{width:'400px', height:'400px'}} src={require('../../assets/images/3dx8.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>


            <section className='product section '>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={3}>
                            <Box className='mock_img left'>
                                <img style={{width:'400px', height:'400px'}} src={require('../../assets/images/3dx16.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={7} sx={{alignContent:"center"}}>
                            <h3 className='title_next'>Virtual Tours and Simulations</h3>
                            <Typography>Explore the Future: Provide your clients or stakeholders with virtual tours that showcase your products, facilities, or services from anywhere in the world. Our VR simulations create lifelike environments that enable users to interact with and experience your offerings as if they were physically present, enhancing understanding and engagement.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section light'>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={7} sx={{alignContent:"center"}}>
                            <h3 className='title_next'>Product Visualization</h3>
                            <Typography>See it Before You Buy: Our advanced product visualization tools use AR and VR to bring your products to life. Customers can interact with 3D models, view products from all angles, and experience them in various contexts. This technology helps in making informed decisions, improving customer satisfaction, and increasing sales.</Typography>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={4} >
                            <Box className='mock_img1 right'>
                                <img style={{width:'400px', height:'400px'}}  src={require('../../assets/images/3dx14.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>


            <section className='product section '>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={3}>
                            <Box className='mock_img left'>
                                <img style={{width:'400px', height:'400px'}} src={require('../../assets/images/3dx4.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={7} sx={{alignContent:"center"}}>
                            <h3 className='title_next'>Metaverse and Gaming</h3>
                            <Typography>Enter New Worlds: Dive into the metaverse with our innovative VR solutions that offer engaging gaming experiences and interactive environments. Our expertise in VR gaming and metaverse development ensures that users enjoy immersive, interactive, and entertaining experiences that push the boundaries of traditional gaming.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section light'>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={7}  sx={{alignContent:"center"}}>
                            <h3 className='title_next'>Interactive Product Design</h3>
                            <Typography>Design and Collaborate: Utilize AR and VR to revolutionize your product design processes. Our solutions facilitate real-time collaboration, visualization, and modification of designs, allowing teams to work together seamlessly, iterate quickly, and bring innovative products to market faster.</Typography>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={4}>
                            <Box className='mock_img1 right'>
                                <img style={{width:'400px', height:'400px'}} src={require('../../assets/images/3dx15.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>


            <section className='product section '>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={3}>
                            <Box className='mock_img left'>
                                <img style={{width:'400px', height:'400px'}} src={require('../../assets/images/3dx3.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={7} sx={{alignContent:"center"}}>
                            <h3 className='title_next'>Customer Service Enhancement</h3>
                            <Typography>Exceptional Support: Enhance your customer service with AR solutions that provide real-time assistance and support. Our interactive customer service tools enable users to receive guidance, troubleshoot issues, and access information in a dynamic and engaging manner, improving overall customer satisfaction.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            {/* <section className='aboutsect section4 p-0'  >
                <Grid container >
                    <Grid md={12} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <Typography >At Koinnovation Solutions, we are at the forefront of integrating cutting-edge technologies to create immersive and transformative experiences. Our focus lies in harnessing the power of 3DExperience, Augmented Reality (AR), and Virtual Reality (VR) to revolutionize how businesses and individuals interact with the digital world. Our innovative solutions span across various domains including training, design, customer engagement, and more, offering a comprehensive suite of interactive experiences that drive engagement and efficiency.</Typography>

                            <ol className='list'>
                                <li>AR and VR Training Solutions</li>
                                    <p>Immersive Learning: Transform your training programs with AR and VR technologies that offer hands-on, interactive learning experiences. Our solutions allow trainees to engage in realistic simulations, practice complex tasks, and receive real-time feedback, all within a virtual environment. This approach enhances learning outcomes and reduces training time and costs.</p>
                                <li>Virtual Tours and Simulations</li>
                                    <p>Explore the Future: Provide your clients or stakeholders with virtual tours that showcase your products, facilities, or services from anywhere in the world. Our VR simulations create lifelike environments that enable users to interact with and experience your offerings as if they were physically present, enhancing understanding and engagement.</p>
                                <li>Product Visualization</li>
                                    <p>See it Before You Buy: Our advanced product visualization tools use AR and VR to bring your products to life. Customers can interact with 3D models, view products from all angles, and experience them in various contexts. This technology helps in making informed decisions, improving customer satisfaction, and increasing sales.</p>
                                <li>Metaverse and Gaming</li>
                                    <p>Enter New Worlds: Dive into the metaverse with our innovative VR solutions that offer engaging gaming experiences and interactive environments. Our expertise in VR gaming and metaverse development ensures that users enjoy immersive, interactive, and entertaining experiences that push the boundaries of traditional gaming.</p>
                                <li>Interactive Product Design</li>
                                    <p>Design and Collaborate: Utilize AR and VR to revolutionize your product design processes. Our solutions facilitate real-time collaboration, visualization, and modification of designs, allowing teams to work together seamlessly, iterate quickly, and bring innovative products to market faster.</p>
                                <li>Customer Service Enhancement</li>
                                    <p>Exceptional Support: Enhance your customer service with AR solutions that provide real-time assistance and support. Our interactive customer service tools enable users to receive guidance, troubleshoot issues, and access information in a dynamic and engaging manner, improving overall customer satisfaction.</p>
                            </ol>
                        <p>Contact Us today to learn more about how our 3DExperience solutions can transform your business and help you achieve your goals.</p>
                        </Box>
                    </Grid>
                </Grid>
            </section> */}

            {/* <section className='process light'>
                <Box className='container'>
                    <Grid container justifyContent={'center'}>
                        <Grid item md={8}>
                            <Box className='center section_subtitle' sx={{ mb: 1 }}>5 Steps</Box>
                            <h1 className='section_title center'>UX Design Process</h1>
                            <img src={require('../../assets/images/process.png')} width="100%" />
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section light'>
                <Box className='container'>
                    <h1 className='section_title center'>Our Products</h1>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <h3 className='title_next'>Mobile Sales & Distribution</h3>
                            <Typography>Sales Diary has a unique solution to best manage inventory, delivery, cash transactions, damages, and returns of van goods. Advanced features for route management, location tracking, on-spot billing and real-time updates on the goods.</Typography>

                            <h3>Digital strategy</h3>
                            <ul className='mock_list'>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Testing &amp; optimization</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Back-End Development</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Modern companies are built</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Mobile Development</li>
                            </ul>
                        </Grid>
                        <Grid item md={6}>
                            <Box className='mock_img right'>
                                <Box className='mockup_logo right'>
                                    <img src={require('../../assets/images/products/van_logo.png')} />
                                </Box>
                                <img src={require('../../assets/images/products/mockup1.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section '>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <Box className='mock_img left'>
                                <Box className='mockup_logo left'>
                                    <img src={require('../../assets/images/products/fitout_logo.png')} />
                                </Box>
                                <img src={require('../../assets/images/products/fitoutmockup.jpg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <h3 className='title_next'>RDD - Fitout Management Application</h3>
                            <Typography>RDD ('Retail Design Delivery') is an overall solution for the operation and maintenance of an entire building infrastructure, services and utilities. The system ensures that they are used in an efficient way and are consistent with the purpose for which they have been readied. Mall Management System encompasses operations, facilities management, security, accounts, common area maintenance, marketing, leasing, and all the other functions even remotely related to a mall.</Typography>

                            <h3>Digital strategy</h3>
                            <ul className='mock_list'>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Testing &amp; optimization</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Back-End Development</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Modern companies are built</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Mobile Development</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='product section light'>
                <Box className='container'>
                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <h3 className='title_next'>Maintenance Service Desk</h3>
                            <Typography>Hamrahh is an overall solution for the operation and maintenance of an entire building infrastructure, services and utilities. The system ensures that they are used in an efficient way and are consistent with the purpose for which they have been readied. Mall Management System encompasses operations, facilities management, security, accounts, common area maintenance, marketing, leasing, and all the other functions even remotely related to a mall.</Typography>

                            <h3>Digital strategy</h3>
                            <ul className='mock_list'>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Testing &amp; optimization</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Back-End Development</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Modern companies are built</li>
                                <li><i class="anmo-up-arrow2-svgo-com"></i> Mobile Development</li>
                            </ul>
                        </Grid>
                        <Grid item md={6}>
                            <Box className='mock_img right'>
                                <Box className='mockup_logo left maintenance'>
                                    <img src={require('../../assets/images/products/maintenance.jpg')} />
                                </Box>
                                <img src={require('../../assets/images/products/mockup3.jpg')} width="100%" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section> */}

        </Box>
    )
}

export default ThreeDx
