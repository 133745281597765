import React from 'react';
import headerbg from '../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


let cardList = [
    {
        title: 'Vision',
        content: "Our vision is to serve as a reliable partner to our customers and co-innovate solutions that help them to pioneer in the new digital industrial revolution. To work with an abundant flow of passion, innovation, competence, quality and commitment",
        icon: 'sap.png',
        image: 'vision1.jpg'
    },
    {
        title: 'Mission',
        content: "Our mission is to formulate and model innovative products that solve complex problems of the present world. To help our clients befriend the modern technological advancements and utilise them to the fullest by offering befitting products and solutions. To help them to leap forward into the industry 4.0 and stay abreast in their business endeavours.",
        icon: 'rpa.png',
        image: 'mission1.jpg'
    },
    {
        title: 'Values',
        content: "We are truly committed to our core values of Ethics, Passion, Competence and Quality. We constantly thrive to exceed customer’s expectation.",
        icon: 'digital.png',
        image: 'values1.jpg'
    },
];
let clients = ['1.jpeg', "2.jpeg", "3.jpeg", "4.jpeg", "5.png", "6.png", "7.jpg", "8.jpg", "9.jpeg", "tata.jpg","safa.jpg", "weekendz.jpg","shamel.jpg", "partner3.png"];


function About() {
    return (
        <Box className="page about">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>About Us</h1>
            </section>
            <section className='aboutsect section1'>
                <Box className='container'>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Box className='imagebox'>
                                <img style={{width: '400px'}} src={require('../assets/images/ideastoimpact.jpg')} />
                                {/* <Box className="year-experience">
                                    <span className="number">15</span>
                                    <h4>Years of Industrial Expertise</h4>
                                </Box> */}
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className='rightbox'>
                                <h1 className='section_title'>Ko Innovation Software Solutions</h1>
                                <Box className='section_subtitle'>Ideas To Impact Lives</Box>
                                <Typography>The world transforms with breathtaking speed, urging organizations to continually rethink and realign for a new era. In this dynamic landscape, growth and profitability hinge on harnessing cutting-edge technologies across every facet of business. In today’s fast-paced environment, staying ahead of global advancements is not merely advantageous—it is essential. Embracing innovation empowers businesses to be both nimble and forward-thinking. At KO-Innovation, we are dedicated to crafting ideas and innovations that leave a lasting impression, shaping a future where impact and excellence are intertwined.</Typography>
                                {/* <Grid container >
                                    <Grid md={6}>
                                        <Box className="about-feature-item">
                                            <h4><span>01</span>Client's Satisfaction</h4>
                                            <p>Same as saying through shrinking from toil random text</p>
                                        </Box>
                                    </Grid>
                                    <Grid md={6}>
                                        <Box className="about-feature-item">
                                            <h4><span>02</span>Planning &amp; Strategy</h4>
                                            <p>Same as saying through shrinking from toil random text</p>
                                        </Box>
                                    </Grid>
                                </Grid> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='aboutsect section2 headcontent'>
                <Box className='container'>
                    <h1 className='section_title center' >Ideas That Innovate and Impact Lives</h1>
                    <Grid container>
                        <Typography>Envision transforming your business with a smart ERP system that drives exponential growth and comprehensive management. Picture revolutionizing your operations through cutting-edge automation, powered by artificial intelligence and robotic process automation. Imagine elevating your customer experience with the exceptional capabilities of AI and Machine Learning, creating moments of delight and engagement. As the world shifts toward virtual technologies, interactive solutions, and cloud innovations, staying ahead is not just an option—it's essential for leveraging this monumental change.</Typography>
                        <Typography>KO-Innovation is dedicated to seamlessly guiding our clients into the forefront of advanced technologies. We partner with you throughout your digital transformation journey, walking alongside you from concept to impact.</Typography>
                        <Typography>At KO-Innovation, we believe that every breakthrough idea and innovation springs from a dynamic, intellectual mindset, a philosophy deeply ingrained in our culture.</Typography>
                        {/* <Grid className='center'>
                            <Typography>Envision transforming your business with a smart ERP system that drives exponential growth and comprehensive management. Picture revolutionizing your operations through cutting-edge automation, powered by artificial intelligence and robotic process automation.</Typography>
                            <Typography>Imagine elevating your customer experience with the exceptional capabilities of AI and Machine Learning, creating moments of delight and engagement.</Typography>
                            <Typography>As the world shifts toward virtual technologies, interactive solutions, and cloud innovations, staying ahead is not just an option—it's essential for leveraging this monumental change.</Typography>
                            <Typography>KO-Innovation is dedicated to seamlessly guiding our clients into the forefront of advanced technologies. We partner with you throughout your digital transformation journey, walking alongside you from concept to impact.</Typography>
                            <Typography>At KO-Innovation, we believe that every breakthrough idea and innovation springs from a dynamic, intellectual mindset, a philosophy deeply ingrained in our culture.</Typography>
                        </Grid> */}
                    </Grid>
                </Box>
            </section>

            <section className='aboutsect vision_mission'>
                <Box className='container'>
                    {cardList && cardList.map((data, i) => (
                        <Grid container spacing={2} className="vision_row" key={i}>
                            <Grid item md={8} sm={12}>
                                <Box className=''>
                                    <Box className='title'>{data.title}</Box>
                                    <Box className='content'>{data.content}</Box>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className='img_box'>
                                    <img src={require(`../assets/images/about/${data?.image}`)} width={'100%'} />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </section>

            <section className='aboutsect section4 p-0'>
                <Grid container spacing={2}>
                    <Grid md={6}>
                        <Box className='darkbox bgdark' sx={{ padding: 10, height:'100%',zIndex:'-1' }}>
                            <h1 className='section_title light' >Our Team</h1>
                            <Typography>The KO-Innovation team comprises of innate thinkers and innovative solution experts who have extensive experience across sectors and industries. We are a focussed team of technocrats on a mission to impact lives with our ideas and innovations.</Typography>
                            <Typography>Our technologists have spent years mastering their skills and they bring together decades of technology consulting expertise in delivering infinite enlightening ideas.</Typography>
                            <Typography>When you team-up with KO-Innovation, you get more than just a product or service. You get a trusted partner who is consistently committed to perfection and augmentation of your experience.</Typography>
                        </Box>
                    </Grid>
                    <Grid md={6}>
                        <Box className='boxbg_img fullwidth'>
                            <img style={{width: '100%', height:'100%'}} src={require('../assets/images/ourteam.jpg')} width={'100%'} />
                        </Box>
                    </Grid>
                </Grid>
            </section>

            <section className='aboutsect section5 p-0'>
                <Grid container spacing={2}>
                    <Grid md={6}>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require('../assets/images/ourapproach.jpg')} width={'100%'} />
                        </Box>
                    </Grid>
                    <Grid md={6}>
                        <Box className='darkbox bgdark' sx={{ padding: 10, height:'100%' }}>
                            <h1 className='section_title light' >Our Approach</h1>
                            <Typography>We serve as a reliable partner since we understand the immense power of partnerships first hand. We work with clients at the intersection of trust, technology and business. We share our expertise in seeing the big picture and connecting the dots to create and provide multidimensional solutions. We collaborate creatively by driving sustainable co-innovations around client’s business strategies.</Typography>
                            <Typography>We help organisation(s) innovate ideas that impact and reap copious results, without waiting for years or wasting their precious resources. Important part of this is to work iteratively with agile approach, fast track potential ideas and lower the cost of innovation.</Typography>
                            <Typography>Our work is valuable, and the technologies that we are building are going to be a key part of the next phase of digital (r)evolution. With our customer focused approach and design oriented methodology, coupled with trust and a strong work ethic at the heart of our collaboration, we aspire to be a social impact business leader.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </section>



            <section className='client_partners ' style={{ background: '#fff' }}>
                <Box className='container'>
                    <h1 className='center section_title'>Our Clients</h1>
                    <OwlCarousel className='owl-theme' items={4} loop margin={20} dots={false} autoplay={true} slideTransition={'linear'} smartSpeed={1000} autoplaySpeed={2000} >
                        {clients.map(data =>
                            <div className='item '>
                                <Box className='banner_wrapper shadowlight'>
                                    <img src={require(`../assets/images/clients/${data}`)} />
                                </Box>
                            </div>
                        )}
                    </OwlCarousel>


                    <h1 className='center section_title mt-30'>Our Partners</h1>
                    {/* <h2 className='center section_subtitle'>“We KO innovate with Octalpha”</h2> */}
                    <Box className='ourpartners'>
                        <Box className='item shadowlight'>
                            <img src={require(`../assets/images/clients/partner.png`)} width={'100%'} />
                        </Box>
                        <Box className='item shadowlight'>
                            <img src={require(`../assets/images/clients/partner2.png`)} width={'100%'} />
                        </Box>
                    </Box>
                </Box>
            </section>

        </Box>
    )
}

export default About
