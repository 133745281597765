import './App.css';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  BrowserRouter
} from "react-router-dom";
import Layout from './pages/Layout';
import About from './pages/About';
import Contact from './pages/Contact';
import Insights from './pages/Insights';
import Career from './pages/Careers';
import SAP from './pages/services/SAP';
import AI from './pages/services/Ai';
import Digital from './pages/services/Digital-products';
import ThreeDx from './pages/services/ThreeDx'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='insights' element={<Insights />} />
          <Route path='careers' element={<Career />} />
          <Route path='contact' element={<Contact />} />
          <Route path='/sap' element={<SAP />} />
          <Route path='/ai' element={<AI />} />
          <Route path='/digital-products' element={<Digital />} />
          <Route path='service' >
            <Route path='sap' element={<SAP />} />
            <Route path='ai' element={<AI />} />
            <Route path='digital-products' element={<Digital />} />
            <Route path='3dexperience' element={<ThreeDx />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
