import React from 'react';
import headerbg from '../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';

function Insights() {
    return (
        <Box className="page contact">
            <section className='page_header' style={{ backgroundImage : `url(${headerbg})`  }}>
                <h1 className='title'>Insights</h1>
            </section>
        </Box>
    )
}

export default Insights
