import React from 'react';
import headerbg from '../../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';

function AI() {
    return (
        <Box className="page contact">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>Cognitive Automation</h1>
            </section>

            <section className='aboutsect section1' style={{ paddingBottom : 20 }}>
                <Box className='container'>
                    <Grid container >
                        <Grid item md={6}>
                            <Box className='imagebox'>
                                <img src={require('../../assets/images/aihome.jpeg')} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className='rightbox'>
                                <h1 className='section_title1' style={{ marginBottom: 15 }}>Machine Learning, Artificial Intelligence and Robotic Process Automation (RPA)</h1>
                                <Typography>At KO-Innovation, we are at the forefront of cognitive automation technologies, revolutionizing how businesses operate and achieve their goals. Our expertise in this field encompasses a suite of advanced technologies, including Machine Learning, Artificial Intelligence (AI), and Robotic Process Automation (RPA). These tools work in concert to streamline and enhance complex business processes, delivering significant improvements in efficiency and effectiveness.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            {/* <section className='aboutsect section1' style={{ paddingTop : 0 }}>
                <Box className='container'>
                    <Grid container justifyContent={'center'}>
                        <Grid item md={10}>
                            <h3 className=' center ' >By providing quality services we at KO-Innovation have established ourselves as a leading competitor in the IT solutions.</h3>
                            <h2 className='section_title center ' style={{ marginBottom: 15 }}>RPA Services</h2>

                            <Typography>RPA journey starts when we understand the potential capability of technology can create a tool to overcome a lack of resources. When implemented with full passion we are freeing up capacity to handle value-added actions previously not possible, which, in turn, allows you to engage in a more productive and competence of the workforce improves.</Typography>

                            <h2 className='center' >Solution</h2>
                            <Typography>With our use cases created using RPA, we can support you by creating Highly User Friendly, unattended BOTS which can work across a wide variety of processes and tools present in your current business environment.</Typography>
                            <Typography></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </section> */}


            <section className='aboutsect section4 p-0'>
                <Grid container >
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >Implementation Services</h1>
                            <Typography>Conduct discovery workshops to ascertain scope, identify processes, and compute ROI benefits.</Typography>
                            <Typography>Automate initial high value processes to demonstrate and showcase Machine Learning, AI and RPA capabilities while generating significant ROI.</Typography>
                            <Typography>Build onshore, offshore teams that can develop bots for the entire range of identified processes in a programmatic model.</Typography>
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require('../../assets/images/implementation.jpeg')} width={'100%'} />
                        </Box>
                    </Grid>
                </Grid>
            </section>


            <section className='aboutsect section4 p-0'>
                <Grid container >
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require('../../assets/images/support1.jpeg')} width={'100%'} />
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >Support Services</h1>
                            <Typography>Multi-disciplinary skilled RPA team with rich experience are readily available to provide our support services 24/7 according to our client needs. Maintain existing processes and handle change management to ensure developed bots continue to operate optimally.</Typography>
                            <Typography>Ability to quickly transition into ongoing RPA programs that need to be accelerated to meet stakeholder expectations and increase velocity of bot rollouts for the identified processes.</Typography>
                            <Typography>We support to create bots, delivered on the SLAs.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </section>

            <section className='aboutsect section4 p-0'>
                <Grid container >
                    <Grid md={6} item>
                        <Box className='darkbox bgdark' sx={{ padding: 10 }}>
                            <h1 className='section_title light' >Research & Training Services</h1>
                            <Typography>Technology triggers great shifts in such industries as healthcare, automotive, retail, construction, banking, advertising, and more. AI is about to change the job landscape and ways of service delivery.</Typography>
                            <Typography>Our R&D team of data science and analytics experts develop, conduct and report on a wide range of internal projects and We continually look for new methods and approaches to improve our products and services with AI technology.</Typography>
                        </Box>
                    </Grid>
                    <Grid md={6} item>
                        <Box className='boxbg_img fullwidth'>
                            <img src={require('../../assets/images/about/research.jpeg')} width={'100%'} />
                        </Box>
                    </Grid>
                </Grid>
            </section>


        </Box>
    )
}

export default AI
