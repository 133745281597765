import React, { useEffect, useState } from 'react';
import headerbg from '../assets/images/about_header.jpg'
import { Box, Grid, Typography } from '@mui/material';
import axios from "axios";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
const sitekey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // change to your sitekey



function Contact() {
    const [btnLoading, setBtnLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [captchaValid, setCaptchaValid] = useState(false);

    const { register, setError, formState: { errors, isValid, isSubmitting }, reset, handleSubmit } = useForm();
    console.log('isSubmitting', isSubmitting);

    const onCaptchaChange = (e) => {
        setCaptchaValid(e);
    }

    const onSubmit = async data => {
        console.log(isValid, isSubmitting);
        setBtnLoading(true)
        if (setCaptchaValid) {
            try {
                axios.post('http://localhost:3000/contactus', data).then((response) => {
                    console.log(response)
                    if (response.status == 200) {
                        setTimeout(() => {
                            setBtnLoading(false);
                            setIsSubmit(true);
                            reset();
                        }, 1000);
                        setTimeout(() => {
                            setIsSubmit(false);
                        }, 3000);
                    }
                });
            } catch (error) {
                console.log(error)
                setBtnLoading(false)
            }
        }
    };


    return (
        <Box className="page contact">
            <section className='page_header' style={{ backgroundImage: `url(${headerbg})` }}>
                <h1 className='title'>Contact Us</h1>
            </section>

            <section className='contact_address' style={{ paddingBottom: '3em' }}>
                <Box className='container'>
                    <Grid container spacing={3} alignItems={'center'}>
                        {/* <Grid item md={3.7}>
                            <Box className='rightbox'>
                                <Box class="iconBox01">
                                    <span><img src={require('../assets/images/icons/call.png')} alt="phone-alt" /></span>
                                    <h3>Call us</h3>
                                    <Typography>
                                        <a href='tel:+917358869821' >+91 99628 22533</a>
                                        <a href='tel:+917358869821' >+91 82200 03007</a>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid> */}
                        <Grid item md={8}>
                            <Box class="iconBox01 long_col">
                                <span><img src={require('../assets/images/icons/location.png')} alt="location-alt" /></span>
                                <h3>Offshore Delivery Center</h3>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <strong> Chennai </strong>
                                        <Typography style={{ marginBottom: 0 }}>
                                            No: 25, 15th Main Road, Anna Nagar West, Chennai 600040
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <strong>  Trichy </strong>
                                        <Typography style={{ marginBottom: 0 }}>
                                            11, 10th Cross St, Thillai Nagar East, Tennur, Tiruchirappalli, Tamil Nadu 620018
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box className='rightbox'>
                                <Box class="iconBox01">
                                    <span><img src={require('../assets/images/icons/email.png')} alt="mail-alt" /></span>
                                    <h3>Send Mail</h3>
                                    <Typography>
                                        <a href='mailto:hr@koinnovation.com' >hr@koinnovation.com</a>
                                        <a href='mailto:crm@koinnovation.com'>crm@koinnovation.com</a>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </section>


            <section className='enquiry_section light' id='enquiry_contact'>
                <Box className='container'>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item md={10}>
                            <h1 className='section_title center'>Send your messages</h1>
                            <Typography className='center'>We’re a talented global team creating digital experiences that merge imagination and technology.</Typography>


                            <form onSubmit={handleSubmit(onSubmit)} className='contact-form'>
                                <Box className='form-group'>
                                    <input
                                        placeholder='Name'
                                        {...register("name", { required: "Please enter your Name." })} // custom message
                                    />
                                    {errors.name && <p className='error'>{errors.name.message}</p>}

                                </Box>
                                <Box className='form-group' >
                                    <input
                                        placeholder='Email'
                                        {...register("email", { required: "Please enter your Email." })} // custom message
                                    />
                                    {errors.email && <p className='error'>{errors.email.message}</p>}
                                </Box>
                                <Box className='form-group'>
                                    <input
                                        placeholder='Mobile'
                                        {...register("mobile", { required: "Please enter your Mobile." })} // custom message
                                    />
                                    {errors.mobile && <p className='error'>{errors.mobile.message}</p>}
                                </Box>
                                <Box className='form-group'>
                                    <textarea
                                        placeholder='Message'
                                        {...register("message", { required: "Please enter your Message." })} // custom message
                                    />
                                    {errors.message && <p className='error'>{errors.message.message}</p>}
                                </Box>

                                <ReCAPTCHA
                                    sitekey={sitekey}
                                    onChange={onCaptchaChange}
                                />
                                {isSubmit && <div style={{ color: "#8bc34a", marginTop: 20 }}>Thank you for filling out your information!</div>}
                                <button type="submit" className='submit_btn'  >Send Message
                                    {btnLoading && <span className='btn_loader'> </span>}
                                </button>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </section>

            <section className='locationmap p-0'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1885532055317!2d80.19954911416596!3d13.087233590780068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52641ba0cb62e3%3A0x156f069b2f0abd39!2s25%2C%2015th%20Main%20Rd%2C%20J%20Block%2C%20Ranganathan%20Garden%2C%20Anna%20Nagar%2C%20Chennai%2C%20Tamil%20Nadu%20600040!5e0!3m2!1sen!2sin!4v1670821755133!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </Box>
    )
}

export default Contact
