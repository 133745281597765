import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import { Link } from "react-router-dom";

import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useLocation } from "react-router-dom";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';


const pages = ['Services', 'About Us', 'Insights', 'Careers', 'Contacts'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const menus = [
    {
        text: "Services",
        url: '',
        dropdown: [
            { text: 'SAP', url: "/service/sap" },
            { text: "Cognitive Automation", url: "/service/ai" },
            { text: "Software Innovation Suite", url: "/service/digital-products" },
            { text: "3DX (3Dxperience)", url: "/service/3dexperience" }
        ]
    },
    {
        text: "About Us",
        url: '/about',
    },
    // {
    //     text: "Insights",
    //     url: '/insights',
    // },
    {
        text: "Careers",
        url: '/careers',
    },
    {
        text: "Contact Us",
        url: '/contact',
    },
];

const social = [
    {
        icon: <LinkedInIcon />,
        url : "https://www.linkedin.com/company/kovins/"
    },
    {
        icon: <FacebookRoundedIcon />,
        url : "#!"
    },
    {
        icon: <InstagramIcon />,
        url : "#!"
    },
    {
        icon: <TwitterIcon />,
        url : "#!"
    }
];

function Header(params) {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const pathname = useLocation().pathname


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <Box className='topheader'>
                <Container maxWidth="xl">
                    <Box className='topheader_inner'>
                        <Box className='rightinfo' sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                            {/* <Box className='item' sx={{ display: 'flex', alignItems: 'center' }}>
                                <a className='actionlink' href='tel:+918220003007'><WhatsAppIcon /> +91 82200 03007</a>
                            </Box> */}
                            <Box className='item' sx={{ display: 'flex', alignItems: 'center' }}>
                                <a className='actionlink' href='mailto:hr@koinnovation.com'><MailOutlineIcon /> contactus@koinnovation.com</a>
                            </Box>
                        </Box>

                        <ul className='header_list social'>
                            {social.map((list, index) =>
                                <li key={index}>
                                    <a href={list.url} target='_blank' >{list.icon}</a>
                                </li>
                            )}
                        </ul>
                    </Box>
                </Container>
            </Box>
            <AppBar className='bottomheader light' position="static" style={{ background: '#fff' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters style={{ justifyContent: 'space-between' }}> <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                        <Link to={'/'}>
                            <Box className='headerlogo'>
                                <img src={require("../assets/images/logo.png")} className="header_logo" />
                            </Box>
                        </Link>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            LOGO
                        </Typography>
                        <Box className='headermenus' sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                            {menus.map((page, i) => (
                                <Link to={page.url} underline="none"
                                    key={i}
                                    id={`menu${i}`}
                                    onClick={handleCloseNavMenu}
                                    className={`${pathname == page.url ? "selected" : ''}`}
                                >
                                    {page.text}

                                    {page?.dropdown && (
                                        <Box className='dropdownlist'>
                                            {page?.dropdown.map((list, index) => (
                                                <Link to={list.url} underline="none"
                                                    key={index}
                                                    id={`dropdown_menu${index}`}
                                                    onClick={handleCloseNavMenu}
                                                    className={`${pathname == list.url ? "selected" : ''}`}
                                                >
                                                    {list.text}
                                                </Link>
                                            ))}
                                        </Box>
                                    )}
                                </Link>
                            ))}

                            <Button variant='contained' sx={{ ml: 2 }} >
                                <Link to={'contact/#enquiry_contact'} style={{ color : '#fff', fontSize : 14 }}>
                                    Request Consultation
                                </Link>
                            </Button>
                            {/* <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu> */}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default Header;
